import {
   type WelkinPatientProgramResponseDto,
   type PatientCareTeam,
   type WelkinPatientResponseDto,
} from '../welkin';

type CareTeam = Pick<PatientCareTeam, 'id' | 'firstName' | 'lastName'> & {
   isClinician: boolean;
   isTherapist: boolean;
};

type Payer = {
   payerId: string;
   name: string;
};

export enum PatientInsuranceStatus {
   VALID = 'valid',
   PENDING = 'pending',
   INVALID = 'invalid',
}

export type MyPatientInsurance = {
   status: PatientInsuranceStatus;
   isValid: boolean;
   payerCard: Payer;
   payerClaim: Payer;

   planName: string | null;
   coPayAmount: number | null;
   // ISO datetime (time is 00:00:00)
   validUntil: string | null;
   planType: 'clinician' | 'psychotherapist' | null;
};

export type MyPatientResponseDto = Pick<
   WelkinPatientResponseDto,
   | 'id'
   | 'firstName'
   | 'lastName'
   | 'timezone'
   | 'state'
   | 'addressLine1'
   | 'addressLine2'
   | 'city'
   | 'zip'
   | 'phone'
   | 'birthDate'
> & {
   careTeam: CareTeam[];
   insurance: MyPatientInsurance | null;
   isMedicalIntakeRefused: boolean;
   isPatientDischarged: boolean;
   currentProgram: WelkinPatientProgramResponseDto | null;
};
